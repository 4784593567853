@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
    background-color: rgb(174, 166, 166);
} */

@keyframes scaleUp {
    from {
        transform: translate(-50%, -50%) scale(0.9);
        opacity: 0;
    }

    to {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}


.MuiBrandSlider__animation {
    animation: scroll 20s linear infinite;
    /* Adjust duration as needed */
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
        /* Move left by the total width */
    }
}